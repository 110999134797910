<template>
  <div class="content_body RefundBillApply" v-loading="loading">
    <div class="nav_header">
      <el-row>
        <el-col :span="24">
          <el-form :inline="true" size="small" :model="searchParams" @keyup.enter.native="handleSearch">
            <el-form-item label="单据号">
              <el-input v-model="searchParams.ID" clearable size="small" @clear="handleSearch" placeholder="输入单据号"></el-input>
            </el-form-item>
            <el-form-item label="客户">
              <el-input v-model="searchParams.Name" clearable size="small" placeholder="名字、手机号或客户编号" @clear="handleSearch"></el-input>
            </el-form-item>
            <el-form-item label="申请时间">
              <el-date-picker v-model="searchParams.createTime" type="daterange" unlink-panels range-separator="至" :picker-options="pickerOptions" value-format="yyyy-MM-dd" start-placeholder="开始日期" end-placeholder="结束日期" @change="handleSearch"></el-date-picker>
            </el-form-item>
            <el-form-item label="状态">
              <el-select v-model="searchParams.ApprovalStatus" placeholder="请选择审批状态" clearable @change="handleSearch">
                <el-option label="待审批" value="20"></el-option>
                <el-option label="已同意" value="30"></el-option>
                <el-option label="已拒绝" value="40"></el-option>
                <el-option label="已撤销" value="50"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="handleSearch" v-prevent-click>搜索</el-button>
            </el-form-item>
          </el-form>
        </el-col>
      </el-row>
    </div>

    <div class="martp_10">
      <el-table size="small" :data="refoundList">
        <el-table-column prop="ID" label="单据号"></el-table-column>
        <el-table-column label="客户信息">
          <template slot-scope="scope">
            <div>{{ scope.row.CustomerName }}</div>
            <div>手机号：{{ scope.row.PhoneNumber }}</div>
          </template>
        </el-table-column>
        <el-table-column label="退款金额" prop="TotalAmount"><template slot-scope="scope">￥{{scope.row.TotalAmount | NumFormat}}</template></el-table-column>
        <el-table-column label="现金退款金额" prop="PayAmount">
          <template slot-scope="scope">￥{{scope.row.PayAmount | NumFormat}}</template>
        </el-table-column>
        <el-table-column label="卡抵扣退款金额" prop="CardDeductionAmount"> <template slot-scope="scope">￥{{scope.row.CardDeductionAmount | NumFormat}}</template> </el-table-column>
        <!-- <el-table-column label="申请门店" prop="EntityName"></el-table-column> -->
        <el-table-column label="审批状态" prop="ApprovalStatusName"></el-table-column>
        <el-table-column label="申请时间" prop="CreatedOn"></el-table-column>
        <el-table-column label="申请人" prop="CreatedByName"></el-table-column>
        <el-table-column label="操作" width="80px">
          <template slot-scope="scope">
            <el-button type="primary" size="small" @click="showEditDialog(scope.row)">详情</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="pad_15 text_right">
        <el-pagination background v-if="paginations.total > 0" @current-change="handleCurrentChange" :current-page.sync="paginations.page" :page-size="paginations.page_size" :layout="paginations.layout" :total="paginations.total"></el-pagination>
      </div>
    </div>

    <!--详情 弹窗-->
    <el-dialog width="1120px" :visible.sync="dialogVisible">
      <span slot="title">
        <span>退款详情</span>
      </span>
      <div style="height: 60vh" class="font_13">
        <el-scrollbar class="el-scrollbar_height">
          <!-- 订单信息 -->
          <div style="margin-right: 10px">
            <div class="tip" style="margin-top: 0">退款信息</div>
            <el-form label-width="100px" size="small" class="saleInfo">
              <el-row>
                <el-col :span="8">
                  <el-form-item label="退款单:">{{
                    refoundInfo.ID
                  }}</el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="审批状态:">{{
                    refoundInfo.ApprovalStatusName
                  }}</el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="退款时间:">
                    {{refoundInfo.BillDate}}
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="8">
                  <el-form-item label="顾客信息:">{{ refoundInfo.CustomerName }} ({{
                      refoundInfo.PhoneNumber
                    }})
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="申请人:">{{
                    refoundInfo.CreatedByName
                  }}</el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="申请时间:">{{
                    refoundInfo.CreatedOn
                  }}</el-form-item>
                </el-col>
              </el-row>
              <el-row>

                <el-col :span="8">
                  <el-form-item label="退款金额:">￥{{ refoundInfo.TotalAmount | NumFormat }}</el-form-item>
                </el-col>
                <!-- <el-col :span="8">
                  <el-form-item label="现金退款:">￥{{ refoundInfo.PayAmount | NumFormat }}</el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="卡抵扣退款:">￥{{ refoundInfo.CardDeductionAmount | NumFormat }}
                  </el-form-item>
                </el-col> -->
                <el-col :span="16">
                  <el-form-item label="备注信息:">
                    {{refoundInfo.RefundRemark}}
                  </el-form-item>
                </el-col>

              </el-row>

              <el-row v-if="refoundInfo.ApprovalStatus == '30'">
                <el-col :span="8">
                  <el-form-item label="审批人:">{{
                    refoundInfo.ApprovalByName
                  }}</el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="审批时间:">{{
                    refoundInfo.ApprovalOn
                  }}</el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="审批说明:">
                    {{refoundInfo.ApprovalRemark}}
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form>
          </div>
          <div class="tip">退款明细</div>
          <div class="border_left border_right border_top">
            <!-- 项目 -->
            <div v-if="
                refoundInfo.Project != undefined &&
                refoundInfo.Project.length > 0
              ">
              <el-row class="tipback_col pad_10">
                <el-col :span="6">项目</el-col>
                <el-col :span="3">购买数量</el-col>
                <el-col :span="4">购买金额</el-col>
                <el-col :span="3">退款数量</el-col>
                <el-col :span="4">可退金额</el-col>
                <el-col :span="4">退款金额</el-col>
              </el-row>
              <el-row v-for="(item, index) in refoundInfo.Project" :key="index + 'x1'" class="text_left">
                <el-col :span="24" class="pad_10 border_bottom">
                  <el-col :span="6">
                    <div>
                      {{ item.Name }}({{ item.Alias }})
                      <el-tag v-if="item.IsLargess" size="mini" type="danger" class="marlt_10">赠
                      </el-tag>
                    </div>
                  </el-col>
                  <el-col :span="3">x {{item.AccountQuantity}}</el-col>
                  <el-col :span="4">¥ {{ item.AccountTotalAmount | NumFormat }}</el-col>
                  <el-col :span="3">x {{item.Quantity}}</el-col>
                  <el-col :span="4">¥ {{ item.TotalAmount + item.PricePreferentialAmount | NumFormat }}</el-col>
                  <el-col :span="4">¥ {{ item.TotalAmount | NumFormat }}</el-col>
                </el-col>

                <el-col :span="24" v-if="item.RefundBillHandler.length > 0" class="pad_10 padtp_5 padbm_5 border_bottom">
                  <el-row v-for="(handler, pIndex) in item.RefundBillHandler" :key="pIndex + 'h5'">
                    <el-col :span="2">
                      <el-form class="saleHandler" :inline="true" size="mini" label-position="left">
                        <el-form-item :label="`${handler.RefundHandlerName}`"></el-form-item>
                      </el-form>
                    </el-col>
                    <el-col :span="22">
                      <el-form class="saleHandler" :inline="true" size="mini">
                        <el-form-item v-for="(employee, handleIndex) in handler.Employee" :key="handleIndex" :label="`${employee.EmployeeName}:`">{{ employee.Scale.toFixed(2) }}%
                        </el-form-item>
                      </el-form>
                    </el-col>
                  </el-row>
                </el-col>
              </el-row>
            </div>
            <!-- 产品 -->
            <div v-if="
                refoundInfo.Product != undefined &&
                refoundInfo.Product.length > 0
              ">
              <el-row class="tipback_col pad_10">
                <el-col :span="6">产品</el-col>
                <el-col :span="3">购买数量</el-col>
                <el-col :span="4">购买金额</el-col>
                <el-col :span="3">退款数量</el-col>
                <el-col :span="4">可退金额</el-col>
                <el-col :span="4">退款金额</el-col>
              </el-row>
              <el-row v-for="(item, index) in refoundInfo.Product" :key="index + 'x2'" class="text_left">
                <el-col :span="24" class="pad_10 border_bottom border_top">
                  <el-col :span="6">
                    <div>
                      {{ item.Name }}({{ item.Alias }})
                      <el-tag v-if="item.IsLargess" size="mini" type="danger" class="marlt_10">赠
                      </el-tag>
                    </div>
                  </el-col>
                  <el-col :span="3">x {{item.AccountQuantity}}</el-col>
                  <el-col :span="4">¥ {{ item.AccountTotalAmount | NumFormat }}</el-col>
                  <el-col :span="3">x {{item.Quantity}}</el-col>
                  <el-col :span="4">¥ {{ item.TotalAmount + item.PricePreferentialAmount | NumFormat }}</el-col>
                  <el-col :span="4">¥ {{ item.TotalAmount | NumFormat }}</el-col>
                </el-col>
                <el-col :span="24" v-if="item.RefundBillHandler.length > 0" class="pad_10 padtp_5 padbm_5 border_bottom">
                  <el-row v-for="(handler, pIndex) in item.RefundBillHandler" :key="pIndex + 'h5'">
                    <el-col :span="2">
                      <el-form class="saleHandler" :inline="true" size="mini" label-position="left">
                        <el-form-item :label="`${handler.RefundHandlerName}`"></el-form-item>
                      </el-form>
                    </el-col>
                    <el-col :span="22">
                      <el-form class="saleHandler" :inline="true" size="mini">
                        <el-form-item v-for="(employee, handleIndex) in handler.Employee" :key="handleIndex" :label="`${employee.EmployeeName}:`">{{ employee.Scale.toFixed(2) }}%
                        </el-form-item>
                      </el-form>
                    </el-col>
                  </el-row>
                </el-col>
              </el-row>
            </div>
            <!-- 通用次卡 -->
            <div v-if="
                refoundInfo.GeneralCard != undefined &&
                refoundInfo.GeneralCard.length > 0
              ">
              <el-row class="tipback_col pad_10">
                <el-col :span="6">通用次卡</el-col>
                <el-col :span="3">购买卡次数</el-col>
                <el-col :span="4">购买金额</el-col>
                <el-col :span="3">退卡次数 </el-col>
                <el-col :span="4">可退金额</el-col>
                <el-col :span="4">退款金额</el-col>
              </el-row>
              <el-row v-for="(item, index) in refoundInfo.GeneralCard" :key="index + '333'" class="text_left">
                <el-col :span="24" class="pad_10 border_bottom">
                  <el-col :span="6">
                    <div>
                      {{ item.Name }}({{ item.Alias }})
                      <el-tag v-if="item.IsLargess" type="danger" size="mini" class="marlt_10">赠
                      </el-tag>
                    </div>
                    <!-- <div class="color_red font_12 martp_5" v-if="item.Price">
                      ￥{{ item.Price| NumFormat  }}
                    </div> -->
                  </el-col>
                  <el-col :span="3">x {{ item.AccountCardTimes}}</el-col>
                  <el-col :span="4">¥ {{ item.AccountTotalAmount | NumFormat }}</el-col>
                  <el-col :span="3">x {{ item.Quantity}}</el-col>
                  <el-col :span="4">¥ {{ item.TotalAmount + item.PricePreferentialAmount | NumFormat }}</el-col>
                  <el-col :span="4">
                    <div>¥ {{ item.TotalAmount | NumFormat }} </div>
                    <div v-if="item.PricePreferentialAmount" class="font_12 color_gray">手动改价: -{{item.PricePreferentialAmount | NumFormat}}</div>
                  </el-col>
                </el-col>
                <el-col :span="24" v-if="item.RefundBillHandler.length > 0" class="pad_10 padtp_5 padbm_5 border_bottom">
                  <el-row v-for="(handler, pIndex) in item.RefundBillHandler" :key="pIndex + 'h5'">
                    <el-col :span="2">
                      <el-form class="saleHandler" :inline="true" size="mini" label-position="left">
                        <el-form-item :label="`${handler.RefundHandlerName}`"></el-form-item>
                      </el-form>
                    </el-col>
                    <el-col :span="22">
                      <el-form class="saleHandler" :inline="true" size="mini">
                        <el-form-item v-for="(employee, handleIndex) in handler.Employee" :key="handleIndex" :label="`${employee.EmployeeName}:`">{{ employee.Scale.toFixed(2) }}%
                        </el-form-item>
                      </el-form>
                    </el-col>
                  </el-row>
                </el-col>
              </el-row>
            </div>
            <!-- 时效卡 -->
            <div v-if="
                refoundInfo.TimeCard != undefined &&
                refoundInfo.TimeCard.length > 0
              ">
              <el-row class="tipback_col pad_10">
                <el-col :span="6">时效卡</el-col>
                <el-col :span="3">购买数量</el-col>
                <el-col :span="4">购买金额</el-col>
                <el-col :span="3">退款数量</el-col>
                <el-col :span="4">可退金额</el-col>
                <el-col :span="4">退款金额</el-col>
              </el-row>
              <el-row v-for="(item, index) in refoundInfo.TimeCard" :key="index + 'x44'">
                <el-col :span="24" class="border_bottom pad_10">
                  <el-col :span="6">
                    <div>
                      {{ item.Name }}({{ item.Alias }})
                      <el-tag v-if="item.IsLargess" type="danger" size="mini" class="marlt_10">赠
                      </el-tag>
                    </div>
                    <!-- <div class="font_12 color_red martp_5" v-if="item.Price">
                      ￥{{ item.Price | NumFormat }}
                    </div> -->
                  </el-col>
                  <el-col :span="3">x 1</el-col>
                  <el-col :span="4">¥ {{ item.AccountTotalAmount | NumFormat }} </el-col>
                  <el-col :span="3">x 1</el-col>
                  <el-col :span="4">¥ {{ item.TotalAmount + item.PricePreferentialAmount | NumFormat }}</el-col>
                  <el-col :span="4">¥ {{ item.TotalAmount | NumFormat }}</el-col>
                </el-col>
                <el-col :span="24" v-if="item.RefundBillHandler.length > 0" class="pad_10 padtp_5 padbm_5 border_bottom">
                  <el-row v-for="(handler, pIndex) in item.RefundBillHandler" :key="pIndex + 'h5'">
                    <el-col :span="2">
                      <el-form class="saleHandler" :inline="true" size="mini" label-position="left">
                        <el-form-item :label="`${handler.RefundHandlerName}`"></el-form-item>
                      </el-form>
                    </el-col>
                    <el-col :span="22">
                      <el-form class="saleHandler" :inline="true" size="mini">
                        <el-form-item v-for="(employee, handleIndex) in handler.Employee" :key="handleIndex" :label="`${employee.EmployeeName}:`">{{ employee.Scale.toFixed(2) }}%
                        </el-form-item>
                      </el-form>
                    </el-col>
                  </el-row>
                </el-col>
              </el-row>
            </div>
            <!-- 储值卡 -->
            <div v-if="
                refoundInfo.SavingCard != undefined &&
                refoundInfo.SavingCard.length > 0
              ">
              <el-row class="tipback_col pad_10">
                <el-col :span="6">储值卡</el-col>
                <el-col :span="4">购买金额</el-col>
                <el-col :span="4">赠送金额</el-col>
                <!-- <el-col :span="3">可退金额</el-col> -->
                <el-col :span="5">退款金额</el-col>
                <el-col :span="5">退款赠额</el-col>
              </el-row>
              <el-row v-for="(item, index) in refoundInfo.SavingCard" :key="index + 'x45'" class="text_left">
                <el-col :span="24" class="border_bottom pad_10">
                  <el-col :span="6">
                    {{ item.Name }}
                    <span v-if="item.Alias">({{ item.Alias }})</span>
                    <el-tag v-if="item.IsLargess" size="mini" type="danger" class="marlt_10">赠</el-tag>
                  </el-col>
                  <el-col :span="4">￥{{ item.AccountTotalAmount | NumFormat}}</el-col>
                  <el-col :span="4">￥{{ item.AccountLargessAmount | NumFormat}}</el-col>
                  <el-col :span="5">￥{{ item.Amount | NumFormat}}</el-col>
                  <el-col :span="5">￥ {{item.LargessAmount | NumFormat}}</el-col>
                </el-col>
                <el-col :span="24" v-if="item.RefundBillHandler.length > 0" class="pad_10 padtp_5 padbm_5 border_bottom">
                  <el-row v-for="(handler, pIndex) in item.RefundBillHandler" :key="pIndex + 'h5'">
                    <el-col :span="2">
                      <el-form class="saleHandler" :inline="true" size="mini" label-position="left">
                        <el-form-item :label="`${handler.RefundHandlerName}`"></el-form-item>
                      </el-form>
                    </el-col>
                    <el-col :span="22">
                      <el-form class="saleHandler" :inline="true" size="mini">
                        <el-form-item v-for="(employee, handleIndex) in handler.Employee" :key="handleIndex" :label="`${employee.EmployeeName}:`">{{ employee.Scale.toFixed(2) }}%
                        </el-form-item>
                      </el-form>
                    </el-col>
                  </el-row>
                </el-col>
              </el-row>
            </div>

            <!--套餐卡-->
            <div v-if=" refoundInfo.PackageCard != undefined && refoundInfo.PackageCard.length > 0 ">
              <el-row v-for="(item, index) in refoundInfo.PackageCard" :key="index + 'x6'">
                <el-row class="pad_10 border_bottom tipback_col">
                  <el-col :span="6">套餐卡</el-col>
                  <el-col :span="3">购买数量</el-col>
                  <el-col :span="4">购买金额</el-col>
                  <el-col :span="3">退款数量</el-col>
                  <el-col :span="4">可退金额</el-col>
                  <el-col :span="4">退款金额</el-col>
                </el-row>
                <el-row class="pad_10">
                  <el-col :span="6">{{ item.Name}}<span v-if="item.Alias">({{ item.Alias }})</span></el-col>
                  <el-col :span="3">x {{item.AccountQuantity?item.AccountQuantity:1}}</el-col>
                  <el-col :span="4">¥ {{ item.AccountTotalAmount | NumFormat }}</el-col>
                  <el-col :span="3">x {{item.Quantity?item.Quantity:1}}</el-col>
                  <el-col :span="4">¥ {{ item.TotalAmount + item.PricePreferentialAmount | NumFormat }}</el-col>
                  <el-col :span="4">¥ {{ item.TotalAmount | NumFormat }}</el-col>
                </el-row>
                <!-- 项目 -->
                <el-row v-if="item.Project.length > 0">
                  <el-row class="pad_10 border_bottom row_header_package_detail">
                    <el-col :span="6">套餐卡项目</el-col>
                    <el-col :span="3">购买数量</el-col>
                    <el-col :span="4">购买金额</el-col>
                    <el-col :span="3">退款数量</el-col>
                    <el-col :span="4">可退金额</el-col>
                    <el-col :span="4">退款金额</el-col>
                  </el-row>
                  <el-row v-for="(childItem, childIndex) in item.Project" :key="childIndex + 'c6-1'" class="border_bottom">
                    <el-col :span="24" class="pad_10">
                      <el-col :span="6">
                          {{ childItem.Name }}
                          <span v-if="childItem.Alias">({{ childItem.Alias }})</span>
                          <el-tag v-if="childItem.IsLargess" type="danger" size="mini" class="marlt_10">赠</el-tag>
                      </el-col>
                      <el-col :span="3">x {{ childItem.AccountQuantity }}</el-col>
                      <el-col :span="4">¥ {{ childItem.AccountTotalAmount | NumFormat }}</el-col>
                      <el-col :span="3">x {{ childItem.Quantity }}</el-col>
                      <el-col :span="4">¥ {{ childItem.TotalAmount + childItem.PricePreferentialAmount | NumFormat }}</el-col>
                      <el-col :span="4">
                        <div>¥{{ childItem.TotalAmount | NumFormat }}</div>
                        <div v-if="childItem.PricePreferentialAmount" class="font_12 color_gray">手动改价: -{{childItem.PricePreferentialAmount | NumFormat}}</div>
                      </el-col>
                    </el-col>
                  </el-row>
                </el-row>

                <!-- 产品 -->
                <el-row v-if="item.Product.length > 0">
                  <el-row class="pad_10 border_bottom row_header_package_detail">
                    <el-col :span="6">套餐卡产品</el-col>
                    <el-col :span="3">购买数量</el-col>
                    <el-col :span="4">购买金额</el-col>
                    <el-col :span="3">退款数量</el-col>
                    <el-col :span="4">可退金额</el-col>
                    <el-col :span="4">退款金额</el-col>
                  </el-row>
                  <el-row v-for="(childItem, childIndex) in item.Product" :key="childIndex + 'c6-2'" class="border_bottom">
                    <el-col :span="24" class="pad_10">
                      <el-col :span="6">
                        {{ childItem.Name}}<span v-if="childItem.Alias">({{ childItem.Alias }})</span>
                        <el-tag v-if="childItem.IsLargess" type="danger" size="mini" class="marlt_10">赠</el-tag>
                      </el-col>
                      <el-col :span="3">x {{ childItem.AccountQuantity }}</el-col>
                      <el-col :span="4">¥ {{ childItem.AccountTotalAmount | NumFormat }}</el-col>
                      <el-col :span="3">x {{ childItem.Quantity }}</el-col>
                      <el-col :span="4">¥ {{ childItem.TotalAmount + childItem.PricePreferentialAmount | NumFormat }}</el-col>
                      <el-col :span="4">
                        <div>¥{{ childItem.TotalAmount | NumFormat }}</div>
                        <div v-if="childItem.PricePreferentialAmount" class="font_12 color_gray">手动改价: -{{childItem.PricePreferentialAmount | NumFormat}}</div>
                      </el-col>
                    </el-col>
                  </el-row>
                </el-row>

                <!-- 通用次卡 -->
                <el-row v-if="item.GeneralCard.length > 0">
                  <el-row class="pad_10 border_bottom row_header_package_detail">
                    <el-col :span="6">套餐卡通用次卡</el-col>
                    <el-col :span="3">购买卡次数</el-col>
                    <el-col :span="4">购买金额</el-col>
                    <el-col :span="3">退卡次数 </el-col>
                    <el-col :span="4">可退金额</el-col>
                    <el-col :span="4">退款金额</el-col>
                  </el-row>
                  <el-row v-for="(childItem, childIndex) in item.GeneralCard" :key="childIndex + 'c6-3'" class="border_bottom">
                    <el-col :span="24" class="pad_10">
                      <el-col :span="6">
                        {{ childItem.Name}}
                        <span v-if="childItem.Alias">({{ childItem.Alias }})</span>
                        <el-tag v-if="childItem.IsLargess" type="danger" size="mini" class="marlt_10">赠</el-tag>
                      </el-col>
                      <el-col :span="3">x {{item.AccountCardTimes}}</el-col>
                      <el-col :span="4">¥ {{ childItem.AccountTotalAmount | NumFormat }}</el-col>
                      <el-col :span="3">x {{ childItem.Quantity }} 次</el-col>
                      <el-col :span="4">¥ {{ childItem.TotalAmount + childItem.PricePreferentialAmount | NumFormat }}</el-col>
                      <el-col :span="4">
                        <div>¥{{ childItem.TotalAmount | NumFormat }}</div>
                        <div v-if="childItem.PricePreferentialAmount" class="font_12 color_gray">手动改价: -{{childItem.PricePreferentialAmount | NumFormat}}</div>
                      </el-col>
                    </el-col>
                  </el-row>
                </el-row>

                <!-- 时效卡 -->
                <el-row v-if="item.TimeCard.length > 0">
                <el-row class="pad_10 border_bottom row_header_package_detail">
                  <el-col :span="6">套餐卡时效卡</el-col>
                  <el-col :span="3">购买数量</el-col>
                  <el-col :span="4">购买金额</el-col>
                  <el-col :span="3">退款数量</el-col>
                  <el-col :span="4">可退金额</el-col>
                  <el-col :span="4">退款金额</el-col>
                </el-row>
                <el-row v-for="(childItem, childIndex) in item.TimeCard" :key="childIndex + 'c6-4'" class="border_bottom">
                  <el-col :span="24" class="pad_10">
                    <el-col :span="6">
                        {{ childItem.Name}}
                        <span v-if="childItem.Alias">({{ childItem.Alias }})</span>
                        <el-tag v-if="childItem.IsLargess" type="danger" size="mini" class="marlt_10">赠</el-tag>
                    </el-col>
                    <el-col :span="3">x 1</el-col>
                    <el-col :span="4">¥ {{ childItem.AccountTotalAmount | NumFormat }}</el-col>
                    <el-col :span="3">x 1</el-col>
                    <el-col :span="4">¥ {{ childItem.TotalAmount + childItem.PricePreferentialAmount | NumFormat }}</el-col>
                    <el-col :span="4">
                      <div>¥{{ childItem.TotalAmount | NumFormat }}</div>
                      <div v-if="childItem.PricePreferentialAmount" class="font_12 color_gray">手动改价: -{{childItem.PricePreferentialAmount | NumFormat}}</div>
                    </el-col>
                  </el-col>
                </el-row>
                </el-row>

                <!--  储值卡 -->
                <el-row v-if="item.SavingCard.length > 0">
                <el-row class="pad_10 border_bottom row_header_package_detail">
                  <el-col :span="6">套餐卡储值卡</el-col>
                  <el-col :span="4">购买金额</el-col>
                  <el-col :span="4">赠送金额</el-col>
                  <el-col :span="5">退款金额</el-col>
                  <el-col :span="5">退款赠额</el-col>
                </el-row>
                <el-row v-for="(childItem, childIndex) in item.SavingCard" :key="childIndex + 'c6-5'" class="border_bottom">
                  <el-col :span="24" class="pad_10">
                    <el-col :span="6">
                      {{ childItem.Name}}
                      <span v-if="childItem.Alias">({{ childItem.Alias }})</span>
                      <el-tag v-if="childItem.IsLargess" type="danger" size="mini" class="marlt_10">赠</el-tag>
                    </el-col>
                  <el-col :span="4">￥{{ childItem.AccountTotalAmount | NumFormat}}</el-col>
                  <el-col :span="4">￥{{ childItem.LargessAmount | NumFormat}}</el-col>
                  <el-col :span="5">￥{{ childItem.Amount | NumFormat}}</el-col>
                  <el-col :span="5">￥ {{childItem.LargessAmount | NumFormat}}</el-col>
                  </el-col>
                </el-row>
                </el-row>

                <el-col :span="24" v-if="item.RefundBillHandler.length > 0" class="pad_10 padtp_5 padbm_5 border_bottom">
                  <el-row v-for="(handler, pIndex) in item.RefundBillHandler" :key="pIndex + 'h5'">
                    <el-col :span="2">
                      <el-form class="saleHandler" :inline="true" size="mini" label-position="left">
                        <el-form-item :label="`${handler.RefundHandlerName}`"></el-form-item>
                      </el-form>
                    </el-col>
                    <el-col :span="22">
                      <el-form class="saleHandler" :inline="true" size="mini">
                        <el-form-item v-for="(employee, handleIndex) in handler.Employee" :key="handleIndex" :label="`${employee.EmployeeName}:`">{{ employee.Scale.toFixed(2) }}%
                        </el-form-item>
                      </el-form>
                    </el-col>
                  </el-row>
                </el-col>
              </el-row>
            </div>
          </div>

          <div class="border_left border_right border_bottom">
            <el-row>
              <el-col :span="6" :offset="17">
                <el-form class="saleInfo" size="mini">
                  <el-form-item v-if="refoundInfo.PayAmount>0" label="现金退款：">
                    <div class="text_right">
                      ￥{{ Math.abs(refoundInfo.PayAmount) | NumFormat }}
                    </div>
                  </el-form-item>
                  <el-form-item v-if="refoundInfo.CardDeductionAmount>0" label="卡抵扣退款：">
                    <div class="text_right">
                      ￥{{
                        Math.abs(refoundInfo.CardDeductionAmount) | NumFormat
                      }}
                    </div>
                  </el-form-item>
                  <el-form-item label="合计退款：">
                    <div class="text_right">
                      ￥{{ refoundInfo.TotalAmount | NumFormat }}
                    </div>
                  </el-form-item>
                </el-form>
              </el-col>
            </el-row>
          </div>

          <div class="payInfo">
            <div class="tip martp_10 marbm_10">退款信息</div>
            <el-card class="marbm_10" shadow="never" v-if="
                refoundInfo.PayMethod != undefined &&
                refoundInfo.PayMethod.length > 0
              ">
              <div slot="header">
                <span>现金退款</span>
              </div>
              <el-form class="saleInfo" :inline="true" size="mini" label-position="top">
                <el-form-item v-for="(item, index) in refoundInfo.PayMethod" :key="index" :label="item.Name">¥ {{ item.Amount | NumFormat }}</el-form-item>
              </el-form>
            </el-card>
            <el-card shadow="never" v-if="
                refoundInfo.CardDeductionAmount != undefined &&
                refoundInfo.SavingCardDeduction.length > 0
              ">
              <div slot="header">
                <span>卡抵扣退款</span>
              </div>
              <el-form class="saleInfo" :inline="true" size="mini" label-position="top">
                <el-form-item v-for="(item, index) in refoundInfo.SavingCardDeduction" :key="index" :label="item.Name">¥ {{ item.TotalAmount | NumFormat }}</el-form-item>
              </el-form>
            </el-card>
          </div>
        </el-scrollbar>
      </div>
      <span slot="footer" class="dialog-footer text_right">
        <el-button @click="dialogVisible =false" size="small">取消</el-button>
        <el-button v-if="refoundInfo.ApprovalStatus == '20'" type="danger" @click="ApprovalDialog" :loading="approvalLoading" size="small">撤销申请</el-button>
      </span>
    </el-dialog>

  </div>
</template>

<script>
import API from "@/api/iBeauty/RefundBill/refundStock";
var Enumerable = require("linq");
export default {
  name: "RefundBillApply",
  data() {
    return {
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
      },
      loading: false,
      approvalLoading: false,
      dialogVisible: false,
      cancelOrder: false,
      dialogBill: false,
      innerVisible: false,
      searchParams: {
        PageNum: 1,
        ID: "",
        Name: "",
        StartDate: "",
        EndDate: "",
        ApprovalStatus: "",
        createTime: "",
      },
      refoundList: [],
      refoundInfo: {},
      Remark: "",
      //需要给分页组件传的信息
      paginations: {
        page: 1, // 当前位于哪页
        total: 5, // 总数
        page_size: 10, // 1页显示多少条
        layout: "total, prev, pager, next,jumper", // 翻页属性
      },
    };
  },
  methods: {
    // 数据显示
    handleSearch: function () {
      let that = this;
      that.paginations.page = 1;
      that.search();
    },
    // 数据显示
    search: function () {
      var that = this;
      that.loading = true;
      var start = "";
      var end = "";
      if (
        that.searchParams.createTime != null &&
        that.searchParams.createTime != ""
      ) {
        start = that.searchParams.createTime[0];
        end = that.searchParams.createTime[1];
      }
      var params = {
        PageNum: that.paginations.page,
        Name: that.searchParams.Name,
        ID: that.searchParams.ID,
        StartDate: start,
        EndDate: end,
        ApprovalStatus: that.searchParams.ApprovalStatus,
      };
      API.getRefundBillList(params)
        .then((res) => {
          if (res.StateCode == 200) {
            res.List.forEach((p) => {
              if (p.ApprovalStatus == "20") {
                p.ApprovalStatusName = "待审批";
              } else if (p.ApprovalStatus == "30") {
                p.ApprovalStatusName = "已同意";
              } else if (p.ApprovalStatus == "40") {
                p.ApprovalStatusName = "已拒绝";
              } else {
                p.ApprovalStatusName = "已撤销";
              }
            });
            that.refoundList = res.List;
            that.paginations.total = res.Total;
            that.paginations.page_size = res.PageSize;
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },
    upRemarkDialog() {
      var that = this;
      that.Remark = that.refoundInfo.ApprovalRemark;
      that.innerVisible = true;
    },
    ApprovalDialog() {
      var that = this;
      this.submitRevoke();
      that.ApprovalRemark = "";
    },
    //确定撤销
    submitRevoke() {
      var that = this;
      this.$confirm("此操作将取消当前订单, 是否继续?", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          that.approvalLoading = true;
          var params = {
            ID: that.refoundInfo.ID,
          };
          API.revoke(params)
            .then((res) => {
              if (res.StateCode == 200) {
                that.$message.success({
                  message: res.Message,
                  duration: 2000,
                });
                that.dialogBill = false;
                that.dialogVisible = false;
                that.handleSearch();
              } else {
                that.$message.error({
                  message: res.Message,
                  duration: 2000,
                });
              }
            })
            .finally(function () {
              that.approvalLoading = false;
            });
        })
        .catch(() => {
          that.cancelSaleBillLoading = false;
          this.$message({
            type: "info",
            message: "已取消操作",
          });
        });
    },

    //修改备注
    updateRemarkClick() {
      var that = this;
      var params = {
        TreatBillID: that.refoundInfo.ID,
        Remark: that.Remark,
      };
      API.updateRemark(params).then((res) => {
        if (res.StateCode == 200) {
          that.$message.success({
            message: res.Message,
            duration: 2000,
          });
          that.innerVisible = false;
          // that.detailId = that.refoundInfo.ID
          // that.showEditDialog();
        } else {
          that.$message.error({
            message: res.Message,
            duration: 2000,
          });
        }
      });
    },
    // 上下分页
    handleCurrentChange(page) {
      var that = this;
      that.paginations.page = page;
      that.search();
    },
    /**详情 数据    */
    showEditDialog: function (row) {
      var that = this;
      that.loading = true;
      let params = {
        ID: row.ID,
      };
      API.getRefundBillInfo(params)
        .then((res) => {
          if (res.StateCode == 200) {
            if (res.Data.ApprovalStatus == "20") {
              res.Data.ApprovalStatusName = "待审批";
            } else if (res.Data.ApprovalStatus == "30") {
              res.Data.ApprovalStatusName = "已同意";
            } else if (res.Data.ApprovalStatus == "40") {
              res.Data.ApprovalStatusName = "已拒绝";
            } else {
              res.Data.ApprovalStatusName = "已撤销";
            }
            that.refoundInfo = res.Data;
            that.dialogVisible = true;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },

    //撤销申请
    CancelOrder() {
      var that = this;
      var params = {
        ID: that.refoundInfo.ID,
      };
      API.cancel(params).then((res) => {
        if (res.StateCode == 200) {
          that.$message.success({
            message: res.Message,
            duration: 2000,
          });
          that.cancelOrder = false;
          that.dialogVisible = false;
          that.handleSearch();
        } else {
          that.$message.error({
            message: res.Message,
            duration: 2000,
          });
        }
      });
    },
  },
  mounted() {
    var that = this;
    // var date = new Date(),
    //   y = date.getFullYear(),
    //   m = date.getMonth();
    // that.searchParams.createTime = [new Date(y, m, 1), new Date()];
    that.handleSearch();
  },
};
</script>

<style  lang="scss">
.RefundBillApply {
  .saleInfo {
    .el-form-item__label {
      font-size: 13px !important;
    }
    .el-form-item__content {
      font-size: 13px !important;
    }
    .el-form-item {
      margin-bottom: 0px;
    }
  }
  .el-scrollbar_height {
    height: 100%;

    .header_icon {
      background-color: #f4fbff;
      border: 1px solid #4861fc;
      padding: 15px;
      display: flex;
      align-items: center;
      border-radius: 5px;
    }

    .el-scrollbar__wrap {
      overflow-x: hidden;
    }
  }
  .el-dialog__body {
    word-break: normal;
  }

  .tipback_col {
    background-color: #ecf8ff;
  }
  .saleHandler {
    .el-form-item__label {
      font-size: 12px !important;
      line-height: 18px;
      color: #c0c4cc;
    }
    .el-form-item__content {
      font-size: 12px !important;
      line-height: 20px;
      color: #c0c4cc;
    }
    .el-form-item {
      margin-bottom: 0px;
    }
  }

  .payInfo {
    .el-card__header {
      padding-top: 10px;
      padding-bottom: 10px;
    }
    .el-card__body {
      padding-top: 10px;
      padding-bottom: 10px;
    }
  }

  .row_header_package_detail {
    background-color: #f5f7fa;
    padding: 8px;
    color: #303133;
  }
}
</style>